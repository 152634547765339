import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton, AddToFavourites } from '../../components';
import { ensureListing } from '../../util/data';

import css from './ListingPage.module.css';
import hand from './helping_hand_10_green.png';

const getRegistrationInfo = (registrationOptions, key) => {
  return registrationOptions.find(c => c.key === key);
};

const SectionHeading = props => {
  const {
    richTitle,
    listingRegistration,
    registrationOptions,
    showContactUser,
    onContactUser,
    myPronouns,
    publicData,
    currentUser,
    listing,
    isOwnListing,
    onAddToFavourites,
    addToFavouritesInProgress,
    addToFavouritesError,
    currentFavouritesListingId,
  } = props;

  const registration = getRegistrationInfo(registrationOptions, listingRegistration);
  const showRegistration = registration && !registration.hideFromListingInfo;
  const myRegistrationBody =
    publicData && publicData.registrationBody && publicData.registrationBody.toUpperCase();

  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  // check if listing is a booking (requirement) so we can hide addToFavourites functionality
  const { listingtype } = publicData;
  const isRequirement = listingtype === 'requirement';

  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        {isRequirement && isOwnListing ? (
          <img src={hand} alt="Helping Signalise hand" className={css.helpingHandOwnListing}></img>
        ) : isRequirement ? (
          <img src={hand} alt="Helping Signalise hand" className={css.helpingHand}></img>
        ) : null}

        <h1 className={css.title}>
          {richTitle}
          {!isRequirement ? (
            <AddToFavourites
              className={css.favourites}
              listingId={id}
              currentUser={currentUser}
              onAddToFavourites={onAddToFavourites}
              currentFavouritesListingId={currentFavouritesListingId}
              addToFavouritesInProgress={addToFavouritesInProgress}
              addToFavouritesError={addToFavouritesError}
            />
          ) : null}
        </h1>
        <div className={css.author}>
          {myPronouns && !isRequirement ? (
            <p>
              <span>{myPronouns}</span>
            </p>
          ) : null}
          {showRegistration ? (
            <span>
              {myRegistrationBody} {registration.label.toLowerCase()}
            </span>
          ) : null}
          {showContactUser ? (
            <span className={css.contactWrapper}>
              {showRegistration ? <span className={css.separator}>•</span> : null}
              <InlineTextButton rootClassName={css.contactLink} onClick={onContactUser}>
                <FormattedMessage id="ListingPage.contactUser" />
              </InlineTextButton>
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SectionHeading;
