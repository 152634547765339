import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';

import css from './ListingPage.module.css';

const SectionAdditionalQuals = props => {
  const { options, publicData } = props;
  if (!publicData) {
    return null;
  }

  const selectedOptions =
    publicData && publicData.additionalQualifications ? publicData.additionalQualifications : [];
  const selectedConfigOptions = options.filter(o => selectedOptions.find(s => s === o.key));

  return (
    <div className={css.sectionFeatures}>
      {selectedOptions.length > 0 ? (
        <h2 className={css.featuresTitle}>
          <FormattedMessage id="ListingPage.additionalQualificationsTitle" />
        </h2>
      ) : (
        ''
      )}

      <PropertyGroup
        id="ListingPage.additionalQualifications"
        options={selectedConfigOptions}
        selectedOptions={selectedOptions}
        twoColumns={selectedConfigOptions.length > 5}
      />
    </div>
  );
};

export default SectionAdditionalQuals;
