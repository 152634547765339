import React from 'react';
import { bool, oneOfType, object } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  propTypes,
} from '../../util/types';
import { NamedLink } from '../../components';
import EditIcon from './EditIcon';
import { IconSuccess } from '../../components';

import css from './ListingPage.module.css';

export const ActionBarMaybe = props => {
  const { isOwnListing, listing, editParams } = props;
  const state = listing.attributes.state;
  const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
  const isClosed = state === LISTING_STATE_CLOSED;
  const isDraft = state === LISTING_STATE_DRAFT;
  const isRequirement = listing?.attributes?.publicData?.listingtype == 'requirement';

  if (isOwnListing) {
    let ownListingTextTranslationId = 'ListingPage.ownListing';

    if (isOwnListing && isRequirement) ownListingTextTranslationId = 'ListingPage.ownBooking';
    else if (isPendingApproval) {
      if (listing?.attributes?.publicData?.listingtype == 'requirement')
        ownListingTextTranslationId = 'ListingPage.ownBookingPendingApproval';
      else ownListingTextTranslationId = 'ListingPage.ownListingPendingApproval';
    } else if (isClosed) {
      if (isRequirement) ownListingTextTranslationId = 'ListingPage.ownClosedBooking';
      else ownListingTextTranslationId = 'ListingPage.ownClosedListing';
    } else if (isDraft) {
      if (isRequirement) ownListingTextTranslationId = 'ListingPage.ownBookingDraft';
      else ownListingTextTranslationId = 'ListingPage.ownListingDraft';
    }

    // const

    let message;
    if (isRequirement) {
      message = isDraft ? 'ManageListingCard.finishBooking' : 'ManageListingCard.editBooking';
    } else {
      message = isDraft ? 'ListingPage.finishListing' : 'ListingPage.editListing';
    }

    const ownListingTextClasses = classNames(css.ownListingText, {
      [css.ownListingTextPendingApproval]: isPendingApproval,
    });

    return (
      <div className={css.actionBar}>
        <p className={ownListingTextClasses}>
          {isOwnListing && isRequirement && <IconSuccess className={css.editIcon} />}
          <FormattedMessage id={ownListingTextTranslationId} />
        </p>
        {/* No edit link for now, because draft bookings are already sent to CRM and may change there
        independently or via the Sharetribe-disconnected temporary edit form. */}
        {/* <NamedLink className={css.editListingLink} name="EditListingPage" params={editParams}>
          <EditIcon className={css.editIcon} />
          <FormattedMessage id={message} />
        </NamedLink> */}
        <NamedLink
          className={classNames(css.createListingLink, css.requirement)}
          name="ManageBookingsPage"
        >
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.manageBookings" />
          </span>
        </NamedLink>
      </div>
    );
  } else if (isClosed) {
    return (
      <div className={css.actionBar}>
        <p className={css.closedListingText}>
          <FormattedMessage id="ListingPage.closedListing" />
        </p>
      </div>
    );
  }
  return null;
};

ActionBarMaybe.propTypes = {
  isOwnListing: bool.isRequired,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]).isRequired,
  editParams: object.isRequired,
};

ActionBarMaybe.displayName = 'ActionBarMaybe';

export default ActionBarMaybe;
