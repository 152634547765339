import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel } from '../../components';
import ActionBarMaybe from './ActionBarMaybe';

import css from './ListingPage.module.css';

const SectionImages = props => {
  const {
    title,
    listing,
    isOwnListing,
    requirementListing,
    editParams,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
  } = props;

  const hasImages = listing.images && listing.images.length > 0;
  const firstImage = hasImages ? listing.images[0] : null;

  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = listing.id ? (
    <div onClick={e => e.stopPropagation()}>
      <ActionBarMaybe isOwnListing={isOwnListing} listing={listing} editParams={editParams} />
    </div>
  ) : null;

  const viewPhotosButton =
    hasImages && !requirementListing ? (
      <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
        <FormattedMessage
          id="ListingPage.viewImagesButton"
          values={{ count: listing.images.length }}
        />
      </button>
    ) : null;

  const { publicData } = listing.attributes;
  const category = publicData.listingtype;
  const isRequirement = category === 'requirement';
  const registration = publicData.registration;
  const professionalPhoto = listing.image;

  const hasNoPhoto = professionalPhoto === null || [];

  return (
    <div className={css.sectionImages}>
      <div className={css.threeToTwoWrapper}>
        {isRequirement ? (
          <div className={css.aspectWrapperRequirement} onClick={handleViewPhotosClick}>
            {actionBar}
            {!isRequirement && (
              <ResponsiveImage
                rootClassName={css.rootForImage}
                alt={title}
                image={firstImage}
                variants={[
                  'landscape-crop',
                  'landscape-crop2x',
                  'landscape-crop4x',
                  'landscape-crop6x',
                ]}
                isRequirement={isRequirement}
                registration={registration}
              />
            )}
            {!requirementListing && viewPhotosButton}
          </div>
        ) : (
          <div className={css.aspectWrapper} onClick={handleViewPhotosClick}>
            {actionBar}
            {!isRequirement && (
              <ResponsiveImage
                rootClassName={css.rootForImage}
                alt={title}
                image={firstImage}
                variants={[
                  'landscape-crop',
                  'landscape-crop2x',
                  'landscape-crop4x',
                  'landscape-crop6x',
                ]}
                isRequirement={isRequirement}
                registration={registration}
              />
            )}
            {!requirementListing && viewPhotosButton}
          </div>
        )}
      </div>
      {!requirementListing && (
        <Modal
          id="ListingPage.imageCarousel"
          scrollLayerClassName={css.carouselModalScrollLayer}
          containerClassName={css.carouselModalContainer}
          lightCloseButton
          isOpen={imageCarouselOpen}
          onClose={onImageCarouselClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <ImageCarousel
            images={listing.images}
            imageVariants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
          />
        </Modal>
      )}
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel
          images={listing.images}
          imageVariants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
        />
      </Modal>
    </div>
  );
};

export default SectionImages;
