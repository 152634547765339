import moment from 'moment';
import React from 'react';
import css from './SectionRequirementsMaybe.module.css';

function SectionRequirementsMaybe({ publicData }) {
  const {
    interpretersNeeded,
    cpType,
    personOrRemote,
    requirementEnd,
    requirementStart,
    preferredSex,
    firstPartPostcode,
  } = publicData;

  return (
    <div>
      <section className={css.interpreters}>
        <h3 className={css.descriptionTitle}>
          Type of Communication Professional required: &nbsp;
          <span
            style={{
              fontWeight: 'normal',
              color: '#222',
              display: 'block',
              marginTop: '12px',
              marginBottom: '24px',
            }}
          >
            <p>{cpType}</p>
          </span>
        </h3>
        <h3 className={css.descriptionTitle}>
          Number of Communication professionals required: &nbsp;
          <span
            style={{
              fontWeight: 'normal',
              color: '#222',
              display: 'block',
              marginTop: '12px',
              marginBottom: '24px',
            }}
          >
            <p>{interpretersNeeded}</p>
          </span>
        </h3>
        <h3 className={css.descriptionTitle}>
          Preferred sex of the interpreter for this booking: &nbsp;
          <span
            style={{
              fontWeight: 'normal',
              color: '#222',
              display: 'block',
              marginTop: '12px',
            }}
          >
            <p>{preferredSex}</p>
          </span>
        </h3>
      </section>

      <section className="row" className={css.container}>
        <div className={css.itemContainer}>
          {personOrRemote == 'In person' ? (
            <PersonIcon style={{ width: '40px' }} />
          ) : (
            <DesktopIcon style={{ width: '40px' }} />
          )}
          <div className={css.dataContainer}>
            <h3 style={{ margin: 0 }}>Location</h3>
            <p style={{ margin: 0 }}>
              {personOrRemote}
              {firstPartPostcode ? ` – first part of postcode: ${firstPartPostcode}` : null}
            </p>
            {personOrRemote == 'Remote' ? (
              <small>
                The person booking you will provide you with the details on how to join the event
                using their video meeting software e.g. Zoom link.
              </small>
            ) : null}
          </div>
        </div>
        <div className={css.itemContainer}>
          <CalendarIcon style={{ width: '40px' }} />
          <div className={css.dataContainer}>
            <h3 style={{ margin: 0 }}>Booking starts</h3>
            <p style={{ margin: 0 }}>
              {moment(requirementStart).format('dddd Do MMMM YYYY, h:mma')}
            </p>
          </div>
        </div>
        <div className={css.itemContainer}>
          <CalendarIcon style={{ width: '40px' }} />
          <div className={css.dataContainer}>
            <h3 style={{ margin: 0 }}>Booking ends</h3>
            <p style={{ margin: 0 }}>{moment(requirementEnd).format('dddd Do MMMM YYYY, h:mma')}</p>
          </div>
        </div>
      </section>
    </div>
  );
}

const CalendarIcon = props => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    viewBox="0 0 20 20"
    fill="#5d02c7"
  >
    <path
      fillRule="evenodd"
      d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
      clipRule="evenodd"
    />
  </svg>
);

const PersonIcon = props => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    viewBox="0 0 20 20"
    fill="#5d02c7"
  >
    <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
  </svg>
);

const DesktopIcon = props => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    viewBox="0 0 20 20"
    fill="#5d02c7"
  >
    <path
      fillRule="evenodd"
      d="M3 5a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2h-2.22l.123.489.804.804A1 1 0 0113 18H7a1 1 0 01-.707-1.707l.804-.804L7.22 15H5a2 2 0 01-2-2V5zm5.771 7H5V5h10v7H8.771z"
      clipRule="evenodd"
    />
  </svg>
);

const ClockIcon = props => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    viewBox="0 0 20 20"
    fill="#5d02c7"
  >
    <path
      fillRule="evenodd"
      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
      clipRule="evenodd"
    />
  </svg>
);

export default SectionRequirementsMaybe;
