import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMaybe = props => {
  const { description, requirement, publicData } = props;

  const isRequirement = publicData?.listingtype === 'requirement';
  let data = {};
  const options = findOptionsForSelectFilter('workDomains', config.custom.filters);
  const typeLabel = [publicData?.workDomain];

  const typeLabelOptions = typeLabel.map(c => options?.find(o => o.key === c)?.label);

  if (isRequirement) {
    data = {
      type: typeLabelOptions,
    };
  }

  return description ? (
    <div className={css.sectionDescription}>
      <div>
        {requirement ? (
          <div className={css.sectionDescription}>
            <h2 className={css.descriptionTitle}>
              <span>Type of booking</span>
            </h2>
            <p className={css.description}>{data.type}</p>
          </div>
        ) : null}
      </div>
      <div className={css.sectionDescription}>
        {requirement ? (
          <h2 className={css.descriptionTitle}>
            <span>More Information about the booking</span>
          </h2>
        ) : (
          <h2>
            <FormattedMessage id="ListingPage.descriptionTitle" />
          </h2>
        )}
        <p className={css.description}>
          {richText(description, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
            longWordClass: css.longWord,
          })}
        </p>
      </div>
    </div>
  ) : null;
};

export default SectionDescriptionMaybe;
